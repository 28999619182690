import React from 'react';

//Components


function BackgroundBottom() {


    return (
        <>
            <div className='case-page_background-bottom'></div>
        </>  
    )
}

export default BackgroundBottom;
